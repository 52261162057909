import { createModel } from "@rematch/core";
import * as constants from "../constants/finder";
import shortid from "shortid";
import { RootModel } from ".";
import {
  importFinderOptions,
  checkFinderReadyState,
  waitForFragments,
  fragmentsTreeHeaderReceived,
  fragmentsTreeHeaderErrorReceived,
  fragmentsReceived,
  fragmentsErrorReceived,
  selectFragment,
  waitForClasses,
  classesReceived,
  selectClass,
  waitForField,
  fieldsReceived,
  objectcardReceived,
  objectcardErrorReceived,
  addNewCriteria,
  addRelation,
  removeCriteria,
  removeRelation,
  unlockCriteriaRelations,
  changeRelation,
  predicateReceived,
  changeField,
  fetchFinderFieldsImpl,
  fetchFinderPredicateImpl,
  fetchFinderObjectcardImpl,
  fetchFinderClassesImpl,
  fetchFinderFragmentImpl,
  getPredicateName,
} from "../services/finder";
import {
  AlertInfo,
  AlertLevelType,
  AlertOptions,
  AlertState,
} from "../types/alert";
import {
  FinderReducerState,
  FinderState,
  FinderData,
  FinderOptions,
  ServerFinderFilter,
  FinderViewType,
  ServerFinderFragment,
  ServerFinderClass,
  ServerFinderField,
  FinderObjectcard,
  FinderRelation,
  FinderPredicate,
} from "../types/finder";
import { I18NString } from "../types/modal";
import { TreeHeader } from "../types/tree";
import { ServerError } from "../actions/utils";
import { dispatchError } from "../services/alert";
import { fetchTreeHeaderImpl } from "../services/tree";
const DEFAULT_STATE: FinderReducerState = {};

export const DEFAULT_FINDER_STATE: FinderState = {
  finderId: "default",
  initialized: false,
  isReady: false,
  isFetching: false,
  isHidden: false,
  options: {},
  loadedFields: {
    byId: {},
    fetched: {},
    loading: {},
    idByPredicateName: {},
    children: {},
    rootIds: [],
    allIds: [],
  },
  loadedPredicates: {
    byName: {},
  },
  loadedObjectcards: {
    byId: {},
    fetched: {},
  },
  loadedEnumerations: {
    byId: {},
    fetched: {},
    idByPredicateName: {},
    rootIds: [],
    allIds: [],
  },
  loadedFragments: {
    byId: {},
    fetched: {},
    loading: {},
    error: {},
    children: {},
    rootIds: [],
    allIds: [],
  },
  loadedClasses: {
    byId: {},
    fetched: {},
    loading: {},
    selection: {},
    idByClassId: {},
    children: {},
    allIds: [],
  },
  fragmentsTreeHeader: null,
  fragmentsTreeHeaderError: false,
  classMap: {},
  data: {
    criteria: {
      byId: {},
      allIds: [],
    },
    criteriaGroup: {
      byId: {},
      allIds: [],
    },
    criteriaGroupList: [],
    sideBar: {
      fragmentLevels: [],
      classLevels: [],
      selectedClass: null,
    },
    searchString: null,
  },
  changes: null,
  view: constants.FINDER_VIEW_TYPE_ADD,
  initialFilter: null,
};

const initialState: FinderReducerState = DEFAULT_STATE;

export const finder = createModel<RootModel>()({
  state: initialState,
  reducers: {
    sendFinderOptions(
      state,
      payload: {
        finderId: string;
        options: FinderOptions;
        filter?: ServerFinderFilter | null;
      }
    ) {
      const { finderId, ...data } = payload;
      return receiveFinderOptions(state, data, finderId);
    },
    checkFinderReady(state, payload: { finderId: string }) {
      const { finderId } = payload;
      const newState = { ...state };

      const newFinder = newState[finderId];

      if (!newFinder) {
        return state;
      }
      checkFinderReadyState(newFinder);
      return { ...state, [finderId]: newFinder };
    },
    sendFinderHidden(state, payload: { finderId: string; hidden?: boolean }) {
      const { finderId, ...data } = payload;
      return receiveFinderHidden(state, data, finderId);
    },
    sendFinderView(state, payload: { finderId: string; view: FinderViewType }) {
      const { finderId, ...data } = payload;
      return receiveFinderView(state, data, finderId);
    },
    sendFinderFragmentsLoading(
      state,
      payload: { finderId: string; parentId: string }
    ) {
      const { finderId, ...data } = payload;
      return receiveFinderFragmentsLoading(state, data, finderId);
    },
    sendFinderFragmentsTreeHeader(
      state,
      payload: { finderId: string; treeHeader: TreeHeader }
    ) {
      const { finderId, ...data } = payload;
      return receiveFinderFragmentsTreeHeader(state, data, finderId);
    },
    sendFinderFragmentsTreeHeaderError(state, finderId: string) {
      return receiveFinderFragmentsTreeError(state, null, finderId);
    },
    sendFinderFragments(
      state,
      payload: {
        finderId: string;
        parentId: string;
        fragments: ServerFinderFragment[];
      }
    ) {
      const { finderId, ...data } = payload;
      return receiveFinderFragments(state, data, finderId);
    },
    sendFinderFragmentsError(
      state,
      payload: { finderId: string; parentId: string }
    ) {
      const { finderId, ...data } = payload;
      return receiveFinderFragmentsError(state, data, finderId);
    },
    sendFinderSelectFragment(
      state,
      payload: {
        finderId: string;
        oldFragmentId: string | null;
        newFragmentId: string | null;
        force?: boolean;
      }
    ) {
      const { finderId, ...data } = payload;
      return receiveFinderSelectFragment(state, data, finderId);
    },
    sendFinderClassesLoading(
      state,
      payload: { finderId: string; levelId: string }
    ) {
      const { finderId, ...data } = payload;
      return receiveFinderClassesLoading(state, data, finderId);
    },
    sendFinderClasses(
      state,
      payload: {
        finderId: string;
        levelId: string;
        classes: ServerFinderClass[];
      }
    ) {
      const { finderId, ...data } = payload;
      return receiveFinderClasses(state, data, finderId);
    },
    sendFinderSelectClass(
      state,
      payload: {
        finderId: string;
        oldClassId: string | null;
        newClassId: string | null;
        classLevelIdx: number;
      }
    ) {
      const { finderId, ...data } = payload;
      return receiveFinderSelectClass(state, data, finderId);
    },
    sendFinderFieldsLoading(
      state,
      payload: { finderId: string; parentId: string | null }
    ) {
      const { finderId, ...data } = payload;
      return receiveFinderFieldsLoading(state, data, finderId);
    },
    sendFinderFields(
      state,
      payload: {
        finderId: string;
        parentId: string | null;
        fields: ServerFinderField[];
      }
    ) {
      const { finderId, ...data } = payload;
      return receiveFinderFields(state, data, finderId);
    },
    sendFinderObjectcard(
      state,
      payload: { finderId: string; rdfId: string; objectcard: FinderObjectcard }
    ) {
      const { finderId, ...data } = payload;
      return receiveFinderObjectcard(state, data, finderId);
    },
    sendFinderObjectcardError(
      state,
      payload: { finderId: string; rdfId: string; error: Error }
    ) {
      const { finderId, ...data } = payload;
      return receiveFinderObjectcardError(state, data, finderId);
    },
    sendCriteriaAdd(
      state,
      payload: { finderId: string; criteriaGroupId?: string }
    ) {
      const { finderId, ...data } = payload;
      return receiveFinderCriteriaAdd(state, data, finderId);
    },
    sendCriteriaRelationAdd(
      state,
      payload: { finderId: string; criteriaId: string }
    ) {
      const { finderId, ...data } = payload;
      return receiveFinderCriteriaRelationAdd(state, data, finderId);
    },
    sendCriteriaRemove(
      state,
      payload: { finderId: string; criteriaId: string }
    ) {
      const { finderId, ...data } = payload;
      return receiveFinderCriteriaRemove(state, data, finderId);
    },
    sendCriteriaRelationRemove(
      state,
      payload: { finderId: string; criteriaId: string; relationIdx: number }
    ) {
      const { finderId, ...data } = payload;
      return receiveFinderCriteriaRelationRemove(state, data, finderId);
    },
    sendCriteriaRelationsUnlock(
      state,
      payload: { finderId: string; criteriaId: string }
    ) {
      const { finderId, ...data } = payload;
      return receiveFinderCriteriaRelationsUnlock(state, data, finderId);
    },
    sendCriteriaRelation(
      state,
      payload: {
        finderId: string;
        criteriaId: string;
        relationIdx: number;
        relation: FinderRelation;
      }
    ) {
      const { finderId, ...data } = payload;
      return receiveFinderCriteriaRelation(state, data, finderId);
    },
    sendFinderPredicate(
      state,
      payload: {
        finderId: string;
        predicateName: string;
        predicate: FinderPredicate;
        fieldId?: string;
      }
    ) {
      const { finderId, ...data } = payload;
      return receiveFinderPredicate(state, data, finderId);
    },
    sendCriteriaField(
      state,
      payload: { finderId: string; criteriaId: string; fieldId: string }
    ) {
      const { finderId, ...data } = payload;
      return receiveFinderCriteriaField(state, data, finderId);
    },
    sendFinderSearch(state, payload: { finderId: string; value: string }) {
      const { finderId, ...data } = payload;
      return receiveFinderSearch(state, data, finderId);
    },
    sendFinderChangesConfirm(state, finderId: string) {
      return receiveFinderChangesConfirm(state, null, finderId);
    },
    sendFinderChangesDeny(state, finderId: string) {
      return receiveFinderChangesDeny(state, null, finderId);
    },
  },
  effects: (dispatch) => ({
    initializeFinder: async (
      data: {
        finderId: string;
        finderOptions: FinderOptions;
        finderFilter?: ServerFinderFilter | null;
      },
      state
    ) => {
      const { finderId, finderOptions, finderFilter } = data;
      await dispatch.finder.sendFinderOptions({
        finderId,
        options: finderOptions,
        filter: finderFilter,
      });
      if (!finderFilter) {
        dispatch.finder.checkFinderReady({ finderId });
        return;
      }
      if (finderFilter.criteriaFetchList.length !== 0) {
        await dispatch.finder.fetchFields({ finderId, parentId: null });
      }
      for (let criteriaId of finderFilter.criteriaFetchList) {
        await dispatch.finder.fetchFields({ finderId, parentId: criteriaId });
      }
      for (let predicateName of finderFilter.predicateFetchList) {
        await dispatch.finder.fetchPredicate({ finderId, predicateName });
      }
      for (let subjectRdfId of finderFilter.subjectFetchList) {
        await dispatch.finder.fetchObjectcard({
          finderId,
          rdfId: subjectRdfId,
        });
      }
      for (let classId of finderFilter.classLevelFetchList) {
        await dispatch.finder.fetchFinderClasses({
          finderId,
          model: finderId,
          levelId: classId,
        });
      }
      if (finderFilter.fragmentFetchList.length !== 0) {
        await dispatch.finder.fetchFinderFragment({
          finderId,
          fragmentId: "null",
        });
      }
      for (let fragmentId of finderFilter.fragmentFetchList) {
        await dispatch.finder.fetchFinderFragment({ finderId, fragmentId });
      }
      dispatch.finder.checkFinderReady({ finderId });
    },
    fetchFields: async (
      data: { finderId: string; parentId: string | null },
      s
    ) => {
      const { finderId, parentId } = data;
      const finderState = s.finder[finderId];
      const criteriaTree = finderState.options.criteriaTree;
      if (!criteriaTree) {
        return;
      }
      if (finderState.loadedFields.fetched[parentId || "null"]) {
        /**TODO: add alert */
        return;
      }
      try {
        dispatch.finder.sendFinderFieldsLoading({ finderId, parentId });

        const fields = await fetchFinderFieldsImpl(criteriaTree.path, parentId);
        dispatch.finder.sendFinderFields({ finderId, parentId, fields });
      } catch (e) {
        /**TODO: add alert */
      }
    },
    fetchPredicate: async (
      data: { finderId: string; predicateName: string; fieldId?: string },
      s
    ) => {
      const { finderId, predicateName, fieldId } = data;
      const finderState = s.finder[finderId];
      if (finderState.loadedPredicates.byName[predicateName]) {
        return;
      }
      try {
        const predicate = await fetchFinderPredicateImpl(
          finderId,
          predicateName
        );
        dispatch.finder.sendFinderPredicate({
          finderId,
          predicateName,
          predicate,
          fieldId,
        });
      } catch (e) {
        console.error("Failed to download predicate: ", e);
        if (e instanceof ServerError && e.code === 403) {
          dispatchError("FINDER_DOWNLOAD_PREDICATE_ACCESS_DENIED", e, dispatch);
        } else {
          dispatchError("FINDER_DOWNLOAD_PREDICATE_ERROR", e, dispatch);
        }
      }
    },
    fetchObjectcard: async (data: { finderId: string; rdfId: string }, s) => {
      const { finderId, rdfId } = data;
      const finderState = s.finder[finderId];
      if (finderState.loadedObjectcards.fetched[rdfId]) {
        return;
      }
      try {
        const objectcard = await fetchFinderObjectcardImpl(finderId, rdfId);
        dispatch.finder.sendFinderObjectcard({ finderId, rdfId, objectcard });
      } catch (e: any) {
        dispatch.finder.sendFinderObjectcardError({
          finderId,
          rdfId,
          error: e,
        });
        if (e instanceof ServerError && e.code === 403) {
          dispatchError(
            "FINDER_DOWNLOAD_SUBJECT_HEADER_ACCESS_DENIED",
            e,
            dispatch
          );
        } else {
          dispatchError("FINDER_DOWNLOAD_SUBJECT_HEADER_ERROR", e, dispatch);
        }
      }
    },
    fetchFinderClasses: async (
      data: {
        finderId: string;
        model: string;
        levelId: string;
        isTree?: boolean;
      },
      s
    ) => {
      const { finderId, levelId, model, isTree } = data;
      const finderState = s.finder[finderId];
      if (finderState.loadedClasses.fetched[levelId]) {
        /**TODO: add alert */
        return;
      }
      try {
        dispatch.finder.sendFinderClassesLoading({ finderId, levelId });
        const classes = await fetchFinderClassesImpl(model, levelId, isTree);
        dispatch.finder.sendFinderClasses({ finderId, levelId, classes });
      } catch (e) {
        /**TODO: add alert */
      }
    },
    fetchFinderFragment: async (
      data: { finderId: string; fragmentId: string; types?: string | string[] },
      s
    ) => {
      const { finderId, fragmentId, types } = data;
      const finderState = s.finder[finderId];
      const fragmentTree = finderState.options.fragmentTree;
      if (!fragmentTree) {
        /**TODO: add alert */
        return;
      }
      dispatch.finder.sendFinderFragmentsLoading({
        finderId,
        parentId: fragmentId,
      });
      let treeHeader = finderState.fragmentsTreeHeader;
      try {
        if (!treeHeader) {
          treeHeader = await fetchTreeHeaderImpl(fragmentTree.path);
          dispatch.finder.sendFinderFragmentsTreeHeader({
            finderId,
            treeHeader,
          });
        }
      } catch (e) {
        dispatch.finder.sendFinderFragmentsTreeHeaderError(finderId);
        if (e instanceof ServerError && e.code === 403) {
          dispatchError(
            "FINDER_DOWNLOAD_FRAGMENT_TREE_ACCESS_DENIED",
            e,
            dispatch
          );
        } else {
          dispatchError("FINDER_DOWNLOAD_FRAGMENT_TREE_ERROR", e, dispatch);
        }
        return;
      }
      try {
        const fragments = await fetchFinderFragmentImpl(
          fragmentTree.path,
          fragmentId,
          treeHeader
        );
        dispatch.finder.sendFinderFragments({
          finderId,
          parentId: fragmentId,
          fragments,
        });
      } catch (e) {
        dispatch.finder.sendFinderFragmentsError({
          finderId,
          parentId: fragmentId,
        });
        if (e instanceof ServerError && e.code === 403) {
          dispatchError("FINDER_DOWNLOAD_FRAGMENT_ACCESS_DENIED", e, dispatch);
        } else {
          dispatchError("FINDER_DOWNLOAD_FRAGMENT_ERROR", e, dispatch);
        }
      }
    },
    fetchFinderFragmentList: (
      data: {
        finderId: string;
        parentFragmentIds: string[] | null;
        types: string | string[];
      },
      s
    ) => {
      const { finderId, parentFragmentIds: pIds, types } = data;
      let parentFragmentIds = pIds;
      const finderState = s.finder[finderId];
      const fragmentTree = finderState.options.fragmentTree;
      if (!fragmentTree) {
        /**TODO: add alert */
        return;
      }
      if (parentFragmentIds == null) {
        parentFragmentIds = ["null"];
      }
      for (let fragmentId of parentFragmentIds) {
        if (finderState.loadedFragments.fetched[fragmentId]) {
          continue;
        }
        dispatch.finder.fetchFinderFragment({ finderId, fragmentId, types });
      }
    },
    removeCriteria: (data: { finderId: string; criteriaId: string }, s) => {
      const { criteriaId, finderId } = data;
      const options = {
        title: { id: "MSG_CONFIRM_ACTION" },
        body: { id: "MSG_CONFIRM_REMOVE_CRITERION" },
      };
      dispatch.modal.openModal({
        id: shortid.generate(),
        type: "confirm",
        options,
        okCallback: function () {
          dispatch.finder.sendCriteriaRemove({ finderId, criteriaId });
        },
      });
    },
    removeCriteriaRelation: (
      data: { finderId: string; criteriaId: string; relationIdx: number },
      s
    ) => {
      const { criteriaId, finderId, relationIdx } = data;
      const options = {
        title: { id: "MSG_CONFIRM_ACTION" },
        body: { id: "MSG_CONFIRM_REMOVE_RELATION" },
      };
      dispatch.modal.openModal({
        id: shortid.generate(),
        type: "confirm",
        options,
        okCallback: function () {
          dispatch.finder.sendCriteriaRelationRemove({
            finderId,
            criteriaId,
            relationIdx,
          });
          dispatch.finder.sendCriteriaRelationsUnlock({ finderId, criteriaId });
        },
      });
    },
    changeCriteriaField: (
      data: { finderId: string; criteriaId: string; fieldId: string },
      s
    ) => {
      const { criteriaId, fieldId, finderId } = data;
      const finderState = s.finder[finderId];
      const field = finderState.loadedFields.byId[fieldId];
      if (!field) {
        console.error("Can't find field with id: '" + fieldId + "'");
        return;
      }
      const predicateName = getPredicateName(field);
      if (predicateName) {
        dispatch.finder.fetchPredicate({ finderId, predicateName, fieldId });
      }
      dispatch.finder.sendCriteriaField({ finderId, criteriaId, fieldId });
    },
  }),
});

/*********************
 * Utility funcitons *
 *********************/

function initializeChangedData(finderState: FinderState): FinderData {
  let finderChanges: FinderData;
  if (finderState.changes) {
    finderChanges = { ...finderState.changes };
  } else {
    finderChanges = { ...finderState.data };
  }
  return finderChanges;
}

/****************
 *   Reducers   *
 ****************/

function receiveFinderOptions(
  state: FinderReducerState,
  payload: { options: FinderOptions; filter?: ServerFinderFilter | null },
  finderId: string
): FinderReducerState {
  const nextState = { ...state };
  const finderState = {
    ...(nextState[finderId] || DEFAULT_FINDER_STATE),
    finderId,
  };

  importFinderOptions(finderState, payload.options, payload.filter);

  // checkFinderReadyState(finderState);
  nextState[finderId] = finderState;
  return nextState;
}

function receiveFinderHidden(
  state: FinderReducerState,
  payload: { hidden?: boolean },
  finderId: string
): FinderReducerState {
  const nextState = { ...state };
  const finderState = {
    ...(nextState[finderId] || DEFAULT_FINDER_STATE),
    finderId,
  };
  if (typeof payload.hidden === "undefined") {
    finderState.isHidden = !finderState.isHidden;
  } else {
    finderState.isHidden = payload.hidden;
  }
  nextState[finderId] = finderState;
  return nextState;
}

function receiveFinderView(
  state: FinderReducerState,
  payload: { view: FinderViewType },
  finderId: string
): FinderReducerState {
  const nextState = { ...state };
  const finderState = {
    ...(nextState[finderId] || DEFAULT_FINDER_STATE),
    finderId,
  };
  finderState.view = payload.view;
  nextState[finderId] = finderState;
  return nextState;
}

function receiveFinderFragmentsLoading(
  state: FinderReducerState,
  payload: { parentId: string },
  finderId: string
): FinderReducerState {
  const nextState = { ...state };
  const finderState = {
    ...(nextState[finderId] || DEFAULT_FINDER_STATE),
    finderId,
  };
  waitForFragments(finderState, payload.parentId);
  nextState[finderId] = finderState;
  return nextState;
}

function receiveFinderFragmentsTreeHeader(
  state: FinderReducerState,
  payload: { treeHeader: TreeHeader },
  finderId: string
): FinderReducerState {
  const nextState = { ...state };
  const finderState = {
    ...(nextState[finderId] || DEFAULT_FINDER_STATE),
    finderId,
  };
  fragmentsTreeHeaderReceived(finderState, payload.treeHeader);
  // checkFinderReadyState(finderState);
  nextState[finderId] = finderState;
  return nextState;
}

function receiveFinderFragmentsTreeError(
  state: FinderReducerState,
  payload: null,
  finderId: string
): FinderReducerState {
  const nextState = { ...state };
  const finderState = {
    ...(nextState[finderId] || DEFAULT_FINDER_STATE),
    finderId,
  };
  fragmentsTreeHeaderErrorReceived(finderState);
  checkFinderReadyState(finderState);
  nextState[finderId] = finderState;
  return nextState;
}

function receiveFinderFragments(
  state: FinderReducerState,
  payload: { parentId: string; fragments: ServerFinderFragment[] },
  finderId: string
): FinderReducerState {
  const nextState = { ...state };

  const finderState = {
    ...(nextState[finderId] || DEFAULT_FINDER_STATE),
    finderId,
  };

  fragmentsReceived(finderState, payload.parentId, payload.fragments);
  // checkFinderReadyState(finderState);
  nextState[finderId] = finderState;

  return nextState;
}

function receiveFinderFragmentsError(
  state: FinderReducerState,
  payload: { parentId: string },
  finderId: string
): FinderReducerState {
  const nextState = { ...state };
  const finderState = {
    ...(nextState[finderId] || DEFAULT_FINDER_STATE),
    finderId,
  };
  fragmentsErrorReceived(finderState, payload.parentId);
  // checkFinderReadyState(finderState);
  nextState[finderId] = finderState;
  return nextState;
}

function receiveFinderSelectFragment(
  state: FinderReducerState,
  payload: {
    oldFragmentId: string | null;
    newFragmentId: string | null;
    force?: boolean;
  },
  finderId: string
): FinderReducerState {
  const nextState = { ...state };
  const finderState = {
    ...(nextState[finderId] || DEFAULT_FINDER_STATE),
    finderId,
  };
  if (payload.force) {
    finderState.data = { ...finderState.data };
    selectFragment(
      finderState.data,
      payload.oldFragmentId,
      payload.newFragmentId,
      finderState.loadedFragments
    );
    if (finderState.changes) {
      finderState.changes = { ...finderState.changes };
      selectFragment(
        finderState.changes,
        payload.oldFragmentId,
        payload.newFragmentId,
        finderState.loadedFragments
      );
    }
    checkFinderReadyState(finderState);
  } else {
    finderState.changes = initializeChangedData(finderState);
    finderState.changes = { ...finderState.changes };
    selectFragment(
      finderState.changes,
      payload.oldFragmentId,
      payload.newFragmentId,
      finderState.loadedFragments
    );
  }
  nextState[finderId] = finderState;
  return nextState;
}

function receiveFinderClassesLoading(
  state: FinderReducerState,
  payload: { levelId: string },
  finderId: string
): FinderReducerState {
  const nextState = { ...state };
  const finderState = {
    ...(nextState[finderId] || DEFAULT_FINDER_STATE),
    finderId,
  };
  waitForClasses(finderState, payload.levelId);
  nextState[finderId] = finderState;
  return nextState;
}

function receiveFinderClasses(
  state: FinderReducerState,
  payload: { levelId: string; classes: ServerFinderClass[] },
  finderId: string
): FinderReducerState {
  const nextState = { ...state };
  const finderState = {
    ...(nextState[finderId] || DEFAULT_FINDER_STATE),
    finderId,
  };
  classesReceived(finderState, payload.levelId, payload.classes);
  // checkFinderReadyState(finderState);
  nextState[finderId] = finderState;
  return nextState;
}

function receiveFinderSelectClass(
  state: FinderReducerState,
  payload: {
    oldClassId: string | null;
    newClassId: string | null;
    classLevelIdx: number;
  },
  finderId: string
): FinderReducerState {
  const nextState = { ...state };
  const finderState = {
    ...(nextState[finderId] || DEFAULT_FINDER_STATE),
    finderId,
  };
  finderState.changes = initializeChangedData(finderState);
  selectClass(
    finderState.changes,
    payload.oldClassId,
    payload.newClassId,
    payload.classLevelIdx
  );
  nextState[finderId] = finderState;
  return nextState;
}

function receiveFinderFieldsLoading(
  state: FinderReducerState,
  payload: { parentId: string | null },
  finderId: string
): FinderReducerState {
  const nextState = { ...state };
  const finderState = {
    ...(nextState[finderId] || DEFAULT_FINDER_STATE),
    finderId,
  };
  waitForField(finderState, payload.parentId);
  nextState[finderId] = finderState;
  return nextState;
}

function receiveFinderFields(
  state: FinderReducerState,
  payload: { parentId: string | null; fields: ServerFinderField[] },
  finderId: string
): FinderReducerState {
  const nextState = { ...state };
  const finderState = {
    ...(nextState[finderId] || DEFAULT_FINDER_STATE),
    finderId,
  };
  let filteredData = payload.fields;
  /**TODO: add binding */
  // if (tableData.automation && tableData.automation.predicateFilterBinding) {
  //     const filterFunc = retrieveFunction(tableData.automation.predicateFilterBinding);
  //     filteredData = filteredData.filter((field) => filterFunc(field.predicate));
  // };
  fieldsReceived(finderState, payload.parentId, filteredData);
  // checkFinderReadyState(finderState);
  nextState[finderId] = finderState;
  return nextState;
}

function receiveFinderObjectcard(
  state: FinderReducerState,
  payload: { rdfId: string; objectcard: FinderObjectcard },
  finderId: string
): FinderReducerState {
  const nextState = { ...state };
  const finderState = {
    ...(nextState[finderId] || DEFAULT_FINDER_STATE),
    finderId,
  };
  objectcardReceived(finderState, payload.rdfId, payload.objectcard);
  nextState[finderId] = finderState;
  return nextState;
}

function receiveFinderObjectcardError(
  state: FinderReducerState,
  payload: { rdfId: string; error: Error },
  finderId: string
): FinderReducerState {
  const nextState = { ...state };
  const finderState = {
    ...(nextState[finderId] || DEFAULT_FINDER_STATE),
    finderId,
  };
  objectcardErrorReceived(finderState, payload.rdfId, payload.error);
  nextState[finderId] = finderState;
  return nextState;
}

function receiveFinderCriteriaAdd(
  state: FinderReducerState,
  payload: { criteriaGroupId?: string },
  finderId: string
): FinderReducerState {
  const nextState = { ...state };
  const finderState = {
    ...(nextState[finderId] || DEFAULT_FINDER_STATE),
    finderId,
  };
  finderState.changes = initializeChangedData(finderState);
  addNewCriteria(finderState.changes, payload.criteriaGroupId || null);
  nextState[finderId] = finderState;
  return nextState;
}

function receiveFinderCriteriaRelationAdd(
  state: FinderReducerState,
  payload: { criteriaId: string },
  finderId: string
): FinderReducerState {
  const nextState = { ...state };
  const finderState = {
    ...(nextState[finderId] || DEFAULT_FINDER_STATE),
    finderId,
  };
  finderState.changes = initializeChangedData(finderState);
  addRelation(finderState.changes, payload.criteriaId);
  nextState[finderId] = finderState;
  return nextState;
}

function receiveFinderCriteriaRemove(
  state: FinderReducerState,
  payload: { criteriaId: string },
  finderId: string
): FinderReducerState {
  const nextState = { ...state };
  const finderState = {
    ...(nextState[finderId] || DEFAULT_FINDER_STATE),
    finderId,
  };
  finderState.changes = initializeChangedData(finderState);
  removeCriteria(finderState.changes, payload.criteriaId);
  nextState[finderId] = finderState;
  return nextState;
}

function receiveFinderCriteriaRelationRemove(
  state: FinderReducerState,
  payload: { criteriaId: string; relationIdx: number },
  finderId: string
): FinderReducerState {
  const nextState = { ...state };
  const finderState = {
    ...(nextState[finderId] || DEFAULT_FINDER_STATE),
    finderId,
  };
  finderState.changes = initializeChangedData(finderState);
  removeRelation(finderState.changes, payload.criteriaId, payload.relationIdx);
  nextState[finderId] = finderState;
  return nextState;
}

function receiveFinderCriteriaRelationsUnlock(
  state: FinderReducerState,
  payload: { criteriaId: string },
  finderId: string
): FinderReducerState {
  const nextState = { ...state };
  const finderState = {
    ...(nextState[finderId] || DEFAULT_FINDER_STATE),
    finderId,
  };
  finderState.data = { ...finderState.data };
  unlockCriteriaRelations(finderState.data, payload.criteriaId);
  nextState[finderId] = finderState;
  return nextState;
}

function receiveFinderCriteriaRelation(
  state: FinderReducerState,
  payload: {
    criteriaId: string;
    relationIdx: number;
    relation: FinderRelation;
  },
  finderId: string
): FinderReducerState {
  const nextState = { ...state };
  const finderState = {
    ...(nextState[finderId] || DEFAULT_FINDER_STATE),
    finderId,
  };
  finderState.changes = initializeChangedData(finderState);
  changeRelation(
    finderState.changes,
    payload.criteriaId,
    payload.relationIdx,
    payload.relation
  );
  nextState[finderId] = finderState;
  return nextState;
}

function receiveFinderPredicate(
  state: FinderReducerState,
  payload: {
    predicateName: string;
    predicate: FinderPredicate;
    fieldId?: string;
  },
  finderId: string
): FinderReducerState {
  const nextState = { ...state };
  const finderState = {
    ...(nextState[finderId] || DEFAULT_FINDER_STATE),
    finderId,
  };
  predicateReceived(
    finderState,
    payload.predicateName,
    payload.predicate,
    payload.fieldId
  );
  nextState[finderId] = finderState;
  return nextState;
}

function receiveFinderCriteriaField(
  state: FinderReducerState,
  payload: { criteriaId: string; fieldId: string },
  finderId: string
): FinderReducerState {
  const nextState = { ...state };
  const finderState = {
    ...(nextState[finderId] || DEFAULT_FINDER_STATE),
    finderId,
  };
  finderState.changes = initializeChangedData(finderState);
  changeField(
    finderState.changes,
    payload.criteriaId,
    payload.fieldId,
    finderState.loadedFields
  );
  nextState[finderId] = finderState;
  return nextState;
}

function receiveFinderSearch(
  state: FinderReducerState,
  payload: { value: string },
  finderId: string
): FinderReducerState {
  const nextState = { ...state };
  const finderState = {
    ...(nextState[finderId] || DEFAULT_FINDER_STATE),
    finderId,
  };
  finderState.changes = initializeChangedData(finderState);
  finderState.changes.searchString = payload.value;
  nextState[finderId] = finderState;
  return nextState;
}

function receiveFinderChangesConfirm(
  state: FinderReducerState,
  payload: null,
  finderId: string
): FinderReducerState {
  if (!state[finderId]) {
    return state;
  }
  const nextState = { ...state };
  const finderState = {
    ...(nextState[finderId] || DEFAULT_FINDER_STATE),
    finderId,
  };
  finderState.data = { ...finderState.data, ...finderState.changes };
  finderState.changes = null;
  checkFinderReadyState(finderState);
  nextState[finderId] = finderState;
  return nextState;
}

function receiveFinderChangesDeny(
  state: FinderReducerState,
  payload: null,
  finderId: string
): FinderReducerState {
  if (!state[finderId]) {
    return state;
  }
  const nextState = { ...state };
  const finderState = {
    ...(nextState[finderId] || DEFAULT_FINDER_STATE),
    finderId,
  };
  finderState.changes = null;
  nextState[finderId] = finderState;
  return nextState;
}
