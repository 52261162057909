import * as React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { messages } from "../../index";
import {
  ExecuteSearchEvent,
  SearchCategory,
  SearchResult,
  SearchResultComplexContentItem,
  SearchResultItem,
  SearchResultPathItem,
} from "../../types/search";
import {
  FinderOptionsSettings,
  FinderReducerState,
  ServerFinderFilterWithQuery,
} from "../../types/finder";
import { TreeReducerState } from "../../types/tree";
import { parseFinderToFilterWithQuery } from "../../services/finder";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SearchInput from "./SearchInput";
import SearchInitialImage from "./SearchInitialImage";
import SearchFinder from "./SearchFinder";

import styles from "./Search.module.css";
import { RematchDispatch } from "@rematch/core";
import { RootModel } from "../../model";
import { DEFAULT_FINDER_STATE } from "../../model/finder";
import { RootState } from "../../store";

interface SearchProps {
  className?: string;
  categories?: SearchCategory[];
  activeKey?: string;
  finder?: FinderOptionsSettings;
  finderReducerState: FinderReducerState;
  treeReducerState: TreeReducerState;
  language: string;
  onSearch: (event: ExecuteSearchEvent<Search>) => void;
  fetchTreeHeader: (model: string) => void;
}
interface SearchState {
  initial: boolean;
  search: string;
  category: SearchCategory | null;
  loading: boolean;
  loadingData: boolean;
  error: boolean;
  result: SearchResult;
  pageSize: number;
  ended: boolean;
  visibility: { [k: string]: boolean };
}
class Search extends React.PureComponent<SearchProps, SearchState> {
  constructor(props: SearchProps) {
    super(props);

    this.state = {
      initial: true,
      search: "",
      category: null,
      loading: false,
      loadingData: false,
      error: false,
      result: {
        total: 0,
        items: [],
      },
      pageSize: 100,
      ended: true,
      visibility: {
        sidebar: false,
        criteria: false,
      },
    };

    this.getFinderFilter = this.getFinderFilter.bind(this);
    this.changeVisibility = this.changeVisibility.bind(this);
    this.changeCategory = this.changeCategory.bind(this);
    this.changeSearch = this.changeSearch.bind(this);
    this.finishSearch = this.finishSearch.bind(this);
    this.loadMore = this.loadMore.bind(this);

    /**Event target functions */
    this.sendLoading = this.sendLoading.bind(this);
    this.sendError = this.sendError.bind(this);
    this.sendResult = this.sendResult.bind(this);
  }

  getFinderFilter(
    category = this.state.category
  ): ServerFinderFilterWithQuery | null {
    if (!this.state.visibility.sidebar && !this.state.visibility.criteria) {
      return null;
    }
    const model = category?.model;
    if (!model) {
      return null;
    }
    const finderState = this.props.finderReducerState[model];
    if (!finderState) {
      return null;
    }
    let finderData = finderState.changes || finderState.data;
    if (!finderData) {
      return null;
    }
    finderData = { ...finderData };
    if (!this.state.visibility.sidebar) {
      finderData.sideBar = DEFAULT_FINDER_STATE.data.sideBar;
    }
    if (!this.state.visibility.criteria) {
      finderData.criteria = DEFAULT_FINDER_STATE.data.criteria;
      finderData.criteriaGroup = DEFAULT_FINDER_STATE.data.criteriaGroup;
      finderData.criteriaGroupList =
        DEFAULT_FINDER_STATE.data.criteriaGroupList;
    }
    const filter = parseFinderToFilterWithQuery(
      finderState,
      finderData,
      messages[this.props.language]
    );
    if (
      !filter.orBlocks.length &&
      !filter.classList?.length &&
      !filter.fragmentList?.length
    ) {
      return null;
    }
    return filter;
  }

  changeState(newState: SearchState, withSearch?: boolean) {
    if (!withSearch) {
      this.setState(newState);
      return;
    }
    const finder = this.getFinderFilter(newState.category);
    const startLoading = newState.category && (newState.search || finder);
    if (!startLoading) {
      newState.loading = false;
      newState.loadingData = false;
      newState.error = false;
      newState.result = {
        total: 0,
        items: [],
      };
      newState.ended = true;
    }
    if (newState.initial && startLoading) {
      newState.initial = false;
    }
    this.setState(newState);
    if (startLoading) {
      this.props.onSearch({
        target: this,
        search: newState.search,
        finder: finder,
        category: newState.category as SearchCategory,
        page: 1,
        pageSize: this.state.pageSize,
      });
    }
  }

  changeVisibility(changes: { [k: string]: boolean }) {
    const newState = {
      ...this.state,
      visibility: { ...this.state.visibility, ...changes },
    };
    this.changeState(newState);
  }

  changeCategory(id: string) {
    const category =
      this.props.categories?.find((category) => category.id === id) || null;
    const newState = {
      ...this.state,
      loading: true,
      loadingData: true,
      error: false,
      category,
    };
    this.changeState(newState, true);
  }

  changeSearch(value: string) {
    const newState = { ...this.state, search: value };
    this.changeState(newState);
  }

  finishSearch(value: string) {
    const newState = {
      ...this.state,
      loading: true,
      loadingData: true,
      error: false,
      search: value,
    };
    this.changeState(newState, true);
  }

  loadMore() {
    this.setState({ ...this.state, loadingData: true });
    this.props.onSearch({
      target: this,
      search: this.state.search,
      finder: this.getFinderFilter(),
      category: this.state.category as SearchCategory,
      page: this.state.result.total / this.state.pageSize + 1,
      pageSize: this.state.pageSize,
    });
  }

  sendLoading(loading: boolean) {
    this.setState({ ...this.state, loading, loadingData: loading });
  }

  sendError(error: boolean) {
    this.setState({ ...this.state, loading: false, loadingData: false, error });
  }

  sendResult(result: SearchResult, page?: number) {
    const newState = {
      ...this.state,
      error: false,
      loading: false,
      loadingData: false,
    };
    if (!page || page === 1) {
      newState.result = result;
    } else {
      newState.result = {
        total: newState.result.total + result.total,
        items: newState.result.items.concat(result.items),
      };
    }
    newState.ended = result.total !== this.state.pageSize;
    this.setState(newState);
  }

  componentDidMount() {
    if (this.props.activeKey) {
      this.changeCategory(this.props.activeKey);
    }
    if (this.props.categories) {
      for (let category of this.props.categories) {
        this.props.fetchTreeHeader(category.model);
      }
    }
  }

  render() {
    const finder = this.props.finder;
    if (!finder) {
      return (
        <SearchContainer
          className={this.props.className}
          initial={this.state.initial}
          search={this.state.search}
          categories={this.props.categories}
          category={this.state.category}
          loading={this.state.loading}
          loadingData={this.state.loadingData}
          error={this.state.error}
          result={this.state.result}
          ended={this.state.ended}
          changeSearch={this.changeSearch}
          finishSearch={this.finishSearch}
          changeCategory={this.changeCategory}
          loadMore={this.loadMore}
        />
      );
    }
    const model = this.state.category?.model || "";
    return (
      <SearchFinder
        finderOptions={finder}
        finderId={model}
        model={model}
        treeState={this.props.treeReducerState.treeInfo[model]}
        search={this.state.search}
        visibility={this.state.visibility}
        changeSearch={this.changeSearch}
        finishSearch={this.finishSearch}
        changeVisibility={this.changeVisibility}
      >
        <SearchContainer
          className={this.props.className}
          finder={this.props.finder}
          initial={this.state.initial}
          search={this.state.search}
          categories={this.props.categories}
          category={this.state.category}
          loading={this.state.loading}
          loadingData={this.state.loadingData}
          error={this.state.error}
          result={this.state.result}
          ended={this.state.ended}
          changeSearch={this.changeSearch}
          finishSearch={this.finishSearch}
          changeCategory={this.changeCategory}
          loadMore={this.loadMore}
        />
      </SearchFinder>
    );
  }
}

interface SearchContainerProps {
  className?: string;
  finder?: FinderOptionsSettings;
  initial: boolean;
  search: string;
  categories?: SearchCategory[];
  category: SearchCategory | null;
  loading: boolean;
  loadingData: boolean;
  error: boolean;
  result: SearchResult;
  ended: boolean;
  changeSearch: (value: string) => void;
  finishSearch: (value: string) => void;
  changeCategory: (id: string) => void;
  loadMore: () => void;
}
const SearchContainer: React.FunctionComponent<SearchContainerProps> =
  React.memo((props) => {
    return (
      <div
        className={`w-100 h-100 d-flex flex-column align-items-center ${
          props.className || ""
        } pt-2`}
      >
        {!props.finder && (
          <SearchInput
            value={props.search}
            onChange={props.changeSearch}
            onFinish={props.finishSearch}
          />
        )}
        <SearchCategoryList
          categories={props.categories}
          active={props.category?.id}
          onChange={props.changeCategory}
        />
        <SearchResultList
          error={props.error}
          loading={props.loading}
          loadingData={props.loadingData}
          result={props.result}
          initial={props.initial}
          ended={props.ended}
          loadMore={props.loadMore}
        />
      </div>
    );
  });

interface SearchCategoryListProps {
  categories?: SearchCategory[];
  active?: string;
  onChange: (id: string) => void;
}
const SearchCategoryList: React.FunctionComponent<SearchCategoryListProps> =
  React.memo((props) => {
    if (!props.categories) {
      return null;
    }
    return (
      <div className={`d-flex ${styles.searchCategoryContainer} px-2`}>
        <div className={`d-flex ${styles.searchCategoryList}`}>
          {props.categories.map((category, i) => (
            <SearchCategoryElm
              key={i}
              category={category}
              active={props.active === category.id}
              onClick={props.onChange}
            />
          ))}
        </div>
      </div>
    );
  });

interface SearchCategoryElmProps {
  category: SearchCategory;
  active?: boolean;
  onClick: (id: string) => void;
}
const SearchCategoryElm: React.FunctionComponent<SearchCategoryElmProps> =
  React.memo((props) => {
    const onClick = () => {
      props.onClick(props.category.id);
    };
    return (
      <div
        className={`${styles.searchCategory} ${
          props.active ? styles.active : ""
        } ml-3`}
        onClick={onClick}
      >
        {props.category.icon && (
          <i
            className={`fa ${props.category.icon} mr-1`}
            style={{ color: props.category.iconColor }}
          />
        )}
        <span>{props.category.label}</span>
      </div>
    );
  });

interface SearchResultListProps {
  error: boolean;
  loading: boolean;
  loadingData: boolean;
  result: SearchResult;
  initial: boolean;
  ended: boolean;
  loadMore: () => void;
}
const SearchResultList: React.FunctionComponent<SearchResultListProps> =
  React.memo((props) => {
    if (props.error) {
      return (
        <Alert type="danger">
          <FormattedMessage
            id="NPT_SEARCH_RESULT_ERROR"
            defaultMessage="Error occured during search"
            description="Error occured during search"
          />
        </Alert>
      );
    }
    if (props.loading) {
      return (
        <Alert type="info">
          <FontAwesomeIcon className="mr-2" icon={faCircleNotch} spin={true} />
          <FormattedMessage
            id="NPT_SEARCH_RESULT_LOADING"
            defaultMessage="Searching data..."
            description="Searching data..."
          />
        </Alert>
      );
    }
    if (!props.result.total) {
      if (props.initial) {
        return <SearchInitialImage />;
      }
      return (
        <Alert type="info">
          <FormattedMessage
            id="NPT_SEARCH_RESULT_EMPTY"
            defaultMessage="Result is empty"
            description="Result is empty"
          />
        </Alert>
      );
    }
    return (
      <div className={`d-flex flex-column ${styles.searchResultContainer}`}>
        <div className={`d-flex flex-column ${styles.searchResultList}`}>
          <div className={`${styles.searchResultTotal} my-3 text-secondary`}>
            <FormattedMessage
              id="NPT_SEARCH_RESULT_TOTAL"
              defaultMessage="Results: {total}"
              description="Total results count"
              values={{ total: props.result.total }}
            />
            {!props.ended && <span>+</span>}
          </div>
          {props.result.items.map((result, i) => (
            <SearchResultElm key={i} result={result} />
          ))}
          {!props.ended && (
            <SearchMoreElm
              loading={props.loadingData}
              loadMore={props.loadMore}
            />
          )}
        </div>
      </div>
    );
  });

interface SearchResultElmProps {
  result: SearchResultItem;
}
const SearchResultElm: React.FunctionComponent<SearchResultElmProps> =
  React.memo((props) => {
    return (
      <div className={`${styles.searchResult}`}>
        <SearchResultPathElm items={props.result.path} />
        <SearchResultContentElm content={props.result.content} />
      </div>
    );
  });

interface SearchResultPathElmProps {
  items: SearchResultPathItem[];
}
const SearchResultPathElm: React.FunctionComponent<SearchResultPathElmProps> =
  React.memo((props) => {
    return (
      <div className={`${styles.searchResultLink}`}>
        {props.items.map((item, i) => (
          <div key={i} className={`${styles.searchResultLinkItem}`}>
            <a href={item.link} target={"_blank"}>
              {item.label}
            </a>
          </div>
        ))}
      </div>
    );
  });

interface SearchResultContentElmProps {
  content: string | SearchResultComplexContentItem[];
}
const SearchResultContentElm: React.FunctionComponent<SearchResultContentElmProps> =
  React.memo((props) => {
    if (typeof props.content === "string") {
      return (
        <div className={styles.searchResultDescription}>{props.content}</div>
      );
    }
    return (
      <>
        {props.content.map((item, i) => (
          <SearchResultComplexItemElm key={i} item={item} />
        ))}
      </>
    );
  });

interface SearchResultComplexItemElmProps {
  item: SearchResultComplexContentItem;
}
const SearchResultComplexItemElm: React.FunctionComponent<SearchResultComplexItemElmProps> =
  React.memo((props) => {
    const parts = [];
    if (!props.item.highLights || props.item.highLights.length === 0) {
      parts.push({ bold: false, content: props.item.content });
    } else {
      let usedCount = 0;
      for (let highLightItem of props.item.highLights) {
        let highLightIdx = highLightItem.index;
        let highLightEndIdx = highLightItem.index + highLightItem.size;
        if (highLightIdx < usedCount) {
          highLightIdx = usedCount;
        }
        if (highLightEndIdx <= highLightIdx) {
          continue;
        }
        if (highLightIdx !== usedCount) {
          parts.push({
            bold: false,
            content: props.item.content.substring(usedCount, highLightIdx),
          });
        }
        parts.push({
          bold: true,
          content: props.item.content.substring(highLightIdx, highLightEndIdx),
        });
        usedCount = highLightEndIdx;
      }
      if (usedCount < props.item.content.length) {
        parts.push({
          bold: false,
          content: props.item.content.substring(usedCount),
        });
      }
    }
    return (
      <div className={styles.searchResultDescription}>
        <span>{props.item.label}:</span>
        {parts.map((partItem, i) => (
          <span
            key={i}
            className={`${partItem.bold ? "font-weight-bold" : ""}`}
          >
            {partItem.content}
          </span>
        ))}
      </div>
    );
  });

interface SearchMoreElmProps {
  loading: boolean;
  loadMore: () => void;
}
const SearchMoreElm: React.FunctionComponent<SearchMoreElmProps> = React.memo(
  (props) => {
    return (
      <div className={`w-100 d-flex justify-content-center my-3`}>
        <button
          className={`btn btn-primary py-2 px-3`}
          disabled={props.loading}
          onClick={props.loadMore}
        >
          {props.loading && (
            <FontAwesomeIcon
              className="mr-2"
              icon={faCircleNotch}
              spin={true}
            />
          )}
          {props.loading && (
            <FormattedMessage
              id="NPT_SEARCH_RESULT_LOADING"
              defaultMessage="Searching data..."
              description="Searching data..."
            />
          )}
          {!props.loading && (
            <FormattedMessage
              id="NPT_SEARCH_GET_MORE"
              defaultMessage="Load more"
              description="Load more button"
            />
          )}
        </button>
      </div>
    );
  }
);

interface AlertProps {
  type: "info" | "danger";
}
const Alert: React.FunctionComponent<AlertProps> = React.memo((props) => {
  return (
    <div
      className={`w-100 d-flex justify-content-center m-3 ${styles.searchAlertContainer}`}
    >
      <div
        className={`d-flex justify-content-center align-items-center alert alert-${props.type}`}
      >
        {props.children}
      </div>
    </div>
  );
});

export default connect(
  (state: RootState) => {
    return {
      finderReducerState: state.finder,
      treeReducerState: state.tree,
      language: state.locale.language,
    };
  },
  (dispatch: RematchDispatch<RootModel>) => {
    return {
      fetchTreeHeader: (model: string) => {
        dispatch.tree.fetchTreeHeader({ path: model });
      },
    };
  }
)(Search);
